/* global config */

'use strict';

angular.module('managerApp').controller('PacksController', (
        $scope, $location, $mdDialog, toast, packs) => {

    $scope.config = config;

    const path = $location.path();
    $scope.firstRun = true;
    $scope.count = 0;
    $scope.items = [];
    $scope.newItem = {};
    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 5,
        totalItems: 0,
        pageChanged: () => {
            getPacks();
        }
    };
    $scope.error = null;

    const getPacks = () => {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                params[i] = $scope.pagination.filters[i];
            }
        }
        packs.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            if ($scope.firstRun)
                $scope.firstRun = false;
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            for (let i = 0; i < $scope.items.length; i++) {
                setIsCIM($scope.items[i]);
            }
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    };

    function setIsCIM(item) {
        item.isCIM = item.type === 'certificat-immatriculation';
    }

    $scope.toggleActive = (item) => {
        item.active = !item.active;
        packs.update(item, 'application/json', item._id).then(() => {
            toast.show('Le pack "' + item.name + '" a bien été mis à jour.', 'success');
        }).catch(() => {
            toast.show('Le pack "' + item.name + '" n\'a pas pu être mis à jour.', 'error');
            item.active = !item.active;
        });
    };

    $scope.showAddEditPopup = (item, index) => {
        if (!item._id) {
            item = {
                /*name: 'Pack' + new Date().toDateString(),
                type: 'certificat-immatriculation',
                price: 29,
                active: true,
                isCIM: true*/
            };
        }
        const origItem = angular.copy(item);
        $mdDialog.show({
            templateUrl: 'app/packs/item.html',
            clickOutsideToClose: false,
            locals: {
                item: item,
                config: $scope.config
            },
            controller: ($scope, $mdDialog, packs, item, config) => {
                $scope.edit = typeof item._id !== 'undefined';
                $scope.item = item;
                $scope.config = config;
                const origItem = angular.copy(item);

                $scope.onFileSelect = (files) => {
                    let file = files[0];
                    if (!file) {
                        return;
                    }
                    let img = document.getElementById('preview');
                    img.onload = () => {
                        var width = img.width, height = img.height;
                        $scope.$apply(function () {
                            $scope.item.newPicture = files[0];
                        });
                    };
                    let reader = new FileReader();
                    reader.onload = (function (_img) {
                        return function (e) {
                            _img.src = e.target.result;
                        };
                    })(img);
                    reader.readAsDataURL(file);
                };

                function prepareReq(_item, update = false) {
                    let data = _item, contentType = config.defaultContentType;
                    if (_item.isCIM) {
                        let formData = new FormData();
                        contentType = undefined;
                        if (item.newPicture) {
                            formData.append('picture', _item.newPicture);
                        }
                        for (let attr in _item) {
                            if (['type', 'name', 'description', 'price', 'active'].indexOf(attr) > -1) {
                                formData.append(attr, _item[attr]);
                            }
                        }
                        data = formData;
                    }
                    return packs[update ? 'update' : 'add'](data, contentType, _item._id);
                }

                $scope.closeDialog = (validated = false, form = null) => {
                    if (!$scope.edit) { // addign a new pack
                        if (!validated) {
                            $mdDialog.cancel();
                        } else {
                            if (!form.$valid) {
                                return;
                            }
                            $mdDialog.hide(prepareReq($scope.item));
                        }
                        return;
                    }
                    const isChanged = !angular.equals($scope.item, origItem);
                    if (!validated) {
                        if (isChanged) {
                            for (let attr in origItem) {
                                $scope.item[attr] = origItem[attr];
                            }
                        }
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) {
                            return;
                        }
                        if (isChanged) {
                            $mdDialog.hide(prepareReq($scope.item, true));
                        } else {
                            $mdDialog.hide();
                        }
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le pack "' + item.name + '" a bien été ' +
                        (item._id ? 'mis à jour' : 'ajouté') + '.', 'success');
                getPacks();
            }
        }).catch((err) => {
            if (err) {
                $scope.items[index] = origItem;
                toast.show(
                        err && err.message
                        ? err.message
                        : 'Une erreur est survenue lors de traitement de votre demande',
                        'error',
                        true
                        );
            }
        });
    };

    $scope.showDeleteConfirm = ($event, pack) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer le pack "<b>` + pack.name + `</b>" ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                packId: pack._id
            },
            controller: ($scope, $mdDialog, toast, packs, packId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        packs.delete(packId).then(() => {
                            toast.show('Le pack a bien été supprimé.', 'success');
                            getPacks();
                        }).catch((err) => {
                            toast.show('Une erreur est survenu lors de la suppression' +
                                    ' de ce pack.');
                        });
                }
                };
            }
        });
    };

    getPacks();

});
